<template>
    <b-card class="center_card" footer-class="my-footer">
      <b-card-title class="title-card-mdp mb-2">Mot de passe oublié ?</b-card-title>
      <b-card-sub-title class="sub-title mb-3">Pour réinitialiser votre mot de passe, merci de renseigner l'email associé à votre compte Applicasol.</b-card-sub-title>
      <b-row no-gutters>
        <b-col>
          <ValidationObserver ref="observer">
            <ValidationProvider name="email" :rules="{required:true,email:true}" v-slot="{errors}">
              <b-form-group
                  class="mt-2"
                  label-class="font-weight-bold"
                  label-size="lg"
                  label-align="left">
                <b-input-group >
                    <b-input-group-text variant="primary">
                      <i class="fas fa-user"></i>
                    </b-input-group-text>
                  <b-form-input
                      placeholder="Email"
                      size="xs"
                      title='Email'
                      type="text"
                      v-model="email">
                  </b-form-input>
                </b-input-group>
                <span style="color:red">{{errors[0]}}</span>
              </b-form-group>
            </ValidationProvider>
          </ValidationObserver>
        </b-col>
      </b-row>
      <div class="valider">
        <button pill type="submit" @click.prevent = "sendMail" class="btn-valid m-2">Valider</button>
      </div>
      <div class="connect">
        <a><router-link to="login">Se connecter</router-link></a>
      </div>
    <!-- <template #footer>
      <span class="footer-card">Copyright © 2009-{{new Date().getFullYear()}} INRAE, Tous droits réservés</span>
    </template> -->
    <div slot="footer" class="p-2">
      <span class="footer-card">Copyright © 2009-{{new Date().getFullYear()}} INRAE, Tous droits réservés</span>
    </div>

    </b-card>
</template>

<script>
import http from '@/http.js'
import Router from '@/router'
import {extend} from 'vee-validate'
import {required,email} from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Ce champ est obligatoire'
});
extend('email', {
  ...email,
  message: "Le format de l'email saisi est incorrect"
});

export default {
  name:'ForgotPassword',

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return{
      email:null,
    }
  },


  methods: {

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async sendMail(){
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        http.verifExistUserByEmail(this.email).then((data)=>{
          if(data === true){
            http.demandeMdp(this.email).then((data) => {
              this.email = data;
              Router.push({name: 'Login'});
            }).then(() => {
              this.$bvToast.toast( "Votre demande a bien été prise en compte, vous allez recevoir un mail pour réinitialiser votre mot de passe.",{
                title: "Mot de passe oublié",
                variant: "success",
                autoHideDelay: 5000,
                toaster: "b-toaster-top-center",
                noCloseButton: true,
                solid: true
              })
            })
          }else{
            this.$bvToast.toast(` l'adresse mail n'est pas reconnue`, {
              variant: "danger",
              autoHideDelay: 10000,
              toaster: "b-toaster-top-center",
              noCloseButton: true,
              solid: true
            });
          }
        })
      } else{
        this.invalid = true;
        window.scrollTo(0, 200);
      }
    }
  }
}

</script>

<style scoped>



</style>
